import React from 'react';
import PropTypes from 'prop-types';

// Style and SEO
import { Col, Grid, Row, styled } from '@smooth-ui/core-sc';
import Helmet from 'react-helmet';

import VideoEmbed from '../../components/VideoEmbed/VideoEmbed';

import { withSettings } from '../../containers/WebApp/SettingsContext';
import PrivateComponent from '_platform/src/utils/PrivateComponent';
import LoadingAnimation from '_platform/src/components/Loading/LoadingAnimation';
import LoadAsync from '_platform/src/utils/LoadAsync';
import BannerVideo from 'components/CustomComponents/Banners/BannerVideo';
import { Link } from 'react-router-dom';
import Button from '_platform/src/components/Button/Button';

const RewardsProvider = LoadAsync(() =>
  import(
    /* webpackChunkName: "rewardsProvider" */ '../RewardsProvider/RewardsProvider'
  )
);

const StatementProvider = LoadAsync(() =>
  import(
    /* webpackChunkName: "statementProvider" */ '../StatementProvider/StatementProvider'
  )
);
const StatementIncentiveTiersMini = LoadAsync(() =>
  import(
    /* webpackChunkName: "statementIncentiveTiers" */ '../../components/StatementRecover/StatementIncentiveTiersMini'
  )
);

const fetchStatements = [
  {
    endpoint: '/Statement/v1/Incentive',
    section: 'incentive',
  },
  {
    endpoint: '/Statement/v1/Incentive/Tiers',
    section: 'incentiveTiers',
  },
];

const PageContainer = styled.div`
  button {
    background-color: #262626;
    min-width: 200px;
  }

  h1 {
    font-weight: normal;
  }
`;

const StatementContainer = styled.div`
  .statement-border {
    border-top: 1px solid #4b4442;
    margin-top: 2.5em;
  }
`;

const HomePage = ({ settings }) => {
  return (
    <PageContainer>
      <Helmet>
        {/* <meta name="description" content="Page description" /> */}
      </Helmet>

      <PrivateComponent
        redirectOnError={{
          pathname: settings.settingsApp.loginPagePath || '/login/',
        }}
      >
        <Grid>
          <Row justifyContent="center">
            <Col md={10} className="text--center">
              <h1>Rewarding Your Business In 2024</h1>
              <p>
                Welcome to Growth Partners, a Distributor Rewards Program from
                Kraft Heinz UK, designed to reward you and your business for
                growth of your Kraft Heinz sales.
              </p>
              <p>
                Partner with Kraft Heinz, make 2024 your year for growth and
                reap the rewards for your business, your team or yourself.
              </p>
              <p>
                We look forward to working with you to grow and ultimately
                achieve your choice of Growth Partner Rewards.
              </p>
            </Col>
          </Row>
        </Grid>
        <StatementContainer>
          <Grid className="spacer--bottom statement-border">
            <Row justifyContent="center">
              <Col className="text--center">
                <h1>Progress Trackers</h1>
                <StatementProvider
                  fetch={fetchStatements}
                  render={({ statement, onStatementUpdateTargetRequest }) => {
                    function onTargetUpdate(values) {
                      return onStatementUpdateTargetRequest(
                        values,
                        fetchStatements
                      );
                    }

                    return !statement || !statement.data ? (
                      <LoadingAnimation /> // Loader instead of `null`?
                    ) : (
                      <StatementIncentiveTiersMini
                        statement={statement.data}
                        onStatementUpdateTargetRequest={onTargetUpdate}
                      />
                    );
                  }}
                />
              </Col>
            </Row>
          </Grid>
        </StatementContainer>
        <BannerVideo>
          <Grid>
            <Row justifyContent="center">
              <Col className="text--center spacer--bottom" md={8}>
                <h1 className="text--white">Watch the launch video</h1>
                <VideoEmbed
                  videoId="pO6Vtp9__xU"
                  title="Kraft Heinz, Growth Partners 2024"
                />
              </Col>
            </Row>
          </Grid>
        </BannerVideo>

        <RewardsProvider
          showFeatured
          rewardLabels={{
            highlightHeading: 'Check out these featured rewards',
          }}
        />

        <Grid>
          <Row justifyContent="center">
            <Col className="text--center spacer" md={8}>
              <Link to="/rewards">
                <Button>Explore more rewards</Button>
              </Link>
            </Col>
          </Row>
        </Grid>
      </PrivateComponent>
    </PageContainer>
  );
};

HomePage.propTypes = {
  settings: PropTypes.object.isRequired,
};

export default withSettings(HomePage);
