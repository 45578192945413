import { styled, th } from '@smooth-ui/core-sc';
//import bgImage1x from './images/video-bg@1x.png';
import bgImage2x from './images/video-bg@2x.png';

const BannerVideo = styled.section`
  align-items: center;
  background-color: ${th('primary')};
  background-image: url('${bgImage2x}');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 500px;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0;
  width: 100%;
  margin: 10px 0 10px 0;
`;

export default BannerVideo;
